import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_TEXT_COLOR, LIGHT_THEME_TEXT_COLOR } from "../constants";

const ThemedTable = ({ size, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <Table
      size={size}
      sx={{
        ...sx,
        '& th': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& td': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& input': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiInputAdornment-root span': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiTableCell-root': {
          ...(darkThemeEnabled && { borderColor: DARK_THEME_INPUT_OUTLINE_COLOR }),
        },
      }}
    >
      {children}
    </Table>
  );
};

export default ThemedTable;
