import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import { useSelector } from "react-redux";
import { DARK_THEME_DISABLED_BUTTON_BG_COLOR, DARK_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_TEXT_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedStepper = ({ activeStep, alternativeLabel, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel={alternativeLabel}
      sx={{
        ...sx,
        backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        '& [id$="_inactive"] circle': {
          ...(darkThemeEnabled && { color: DARK_THEME_DISABLED_BUTTON_BG_COLOR }),
        },
      }}
    >
      {children}
    </Stepper>
  );
};

export default ThemedStepper;
