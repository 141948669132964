import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import ProjectsTable from "../components/ProjectsTable";
import { decoratedFetch } from "../req_utils";
import { animated, useSpring } from "react-spring";
import { DARK_THEME_PAPER_COLOR, LIGHT_THEME_PAPER_COLOR, GENESIS_LOGO_COLOR } from "../constants";
import ThemedTypography from "../components/ThemedTypography";
import ThemedPaper from "../components/ThemedPaper";

const COLORS = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];

const DashboardPage = () => {
  const employee = useSelector((state) => state.employee);
  const [monthlyData, setMonthlyData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  useEffect(() => {
    if (employee && employee.id) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };
      decoratedFetch("/get_project_stats_from_last_year", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setMonthlyData(
            data.map((item) => ({
              monthName: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ][item.month - 1],
              totalProjects: item.project_count,
              proposal_total_sum: item.proposal_total_sum,
            })),
          );
          const statusCounts = data.reduce((acc, curr) => {
            Object.keys(curr.statuses).forEach((status_name) => {
              acc[status_name] =
                (acc[status_name] || 0) + curr.statuses[status_name];
            });
            return acc;
          }, {});
          setPieChartData(
            Object.keys(statusCounts).map((key) => ({
              name: key,
              value: statusCounts[key],
            })),
          );
        })
        .catch((error) => console.error(`Error loading data: ${error}`));
    }
  }, [employee]);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  return (
    <animated.div style={fadeIn}>
      <Grid container spacing={2} sx={{ py: 2, pr: { sm: 0, md: 2 } }}>
        <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ThemedPaper elevation={3} sx={{ p: 2 }}>
            <ThemedTypography
              variant="h6"
              component="h2"
              sx={{
                textAlign: "center"
              }}
              pb={5}
            >
              Total Projects by Month
            </ThemedTypography>
            <ResponsiveContainer width="100%" height={300} style={{ backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR }}>
              <BarChart data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monthName" />
                <YAxis
                  domain={[0, Math.max(...monthlyData.map(month => month.totalProjects))]}
                  tick={{ fontSize: 14 }}
                />
                <Tooltip />
                <Bar
                  dataKey="totalProjects"
                  fill="#8884d8"
                  name="Total Projects"
                />
              </BarChart>
            </ResponsiveContainer>
          </ThemedPaper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ThemedPaper elevation={3} sx={{ p: 2 }}>
            <ThemedTypography
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", pb: 5 }}
            >
              Total Proposal Amount By Month
            </ThemedTypography>
            <ResponsiveContainer width="100%" height={300} style={{ backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR }}>
              <LineChart data={monthlyData}>
                <Line
                  type="monotone"
                  dataKey="proposal_total_sum"
                  stroke="green"
                  name="Total Proposal Amount"
                />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="monthName" />
                <YAxis
                  domain={[0, Math.max(...monthlyData.map(month => month.proposal_total_sum))]}
                  tickFormatter={value => {
										if (value >= 1000000) {
											return `$${(value / 1000000).toFixed(1)}M`;
										} else if (value >= 1000) {
											return `$${(value / 1000).toFixed(1)}K`;
										} else {
											return `$${value}`;
										}
                  }}
                  tick={{ fontSize: 14 }}
                />
                <Tooltip
									formatter={value => `$${value}`}
								/>
              </LineChart>
            </ResponsiveContainer>
          </ThemedPaper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ThemedPaper elevation={3} sx={{ p: 2, width: '100%' }}>
            <ThemedTypography
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", paddingBottom: 5 }}
            >
              Project Statuses
            </ThemedTypography>
            <ResponsiveContainer width="100%" height={300} style={{ backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR }}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  outerRadius="80%"
                  fill="#8884d8"
                  dataKey="value"
                  labelLine={false}
                  label={({ name, percent }) =>
                    `${name}: ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </ThemedPaper>
        </Grid>
        <Grid item xs={12}>
					<ThemedTypography variant="h5" gutterBottom sx={{ ml: { xs: '16px', md: '0px' }, mt: "20px", fontWeight: "bold" }}>
						My Projects
					</ThemedTypography>
          <ThemedPaper elevation={3} sx={{ height: "auto", width: "100%" }}>
            <ProjectsTable
              employee_first_name={employee.first_name}
              employee_last_name={employee.last_name}
            />
          </ThemedPaper>
        </Grid>
      </Grid>
    </animated.div>
  );
};

export default DashboardPage;
