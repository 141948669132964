import React, { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_TEXT_COLOR, LIGHT_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedDataGridPro = ({ rows, columns, experimentalFeatures, rowSelectionModel, onRowOrderChange, autoHeight, processRowUpdate, onProcessRowUpdateError, onRowCountChange, hideFooter, slots, initialState, apiRef, getTreeDataPath, groupingMode, onFilterModelChange, pageSize, onRowClick, rowReordering, localeText, treeData, setRowChildren, loading, groupingColDef, sx, disableSelectionOnClick, disableMultipleSelection, onCellKeyDown, onCellClick, checkboxSelection, getRowId, components, getRowClassName, onRowSelectionModelChange }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      onRowOrderChange={onRowOrderChange}
      rowSelectionModel={rowSelectionModel}
      autoHeight={autoHeight}
      slots={slots}
      rowReordering={rowReordering}
      onCellKeyDown={onCellKeyDown}
      initialState={initialState}
      onRowCountChange={onRowCountChange}
      experimentalFeatures={experimentalFeatures}
      apiRef={apiRef}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={onProcessRowUpdateError}
      getTreeDataPath={getTreeDataPath}
      groupingMode={groupingMode}
      onFilterModelChange={onFilterModelChange}
      pageSize={pageSize}
      onRowClick={onRowClick}
      localeText={localeText}
      treeData={treeData}
      setRowChildren={setRowChildren}
      loading={loading}
      disableSelectionOnClick={disableSelectionOnClick}
      disableMultipleSelection={disableMultipleSelection}
      groupingColDef={groupingColDef}
      onCellClick={onCellClick}
      checkboxSelection={checkboxSelection}
      getRowId={getRowId}
      components={components}
      getRowClassName={getRowClassName}
      onRowSelectionModelChange={onRowSelectionModelChange}
      hideFooter={hideFooter}
      sx={{
        ...sx,
				...(darkThemeEnabled && { "--DataGrid-rowBorderColor": DARK_THEME_INPUT_OUTLINE_COLOR }),
        '& .MuiDataGrid-footerContainer': {
					...(darkThemeEnabled && { borderColor: DARK_THEME_INPUT_OUTLINE_COLOR }),
        },
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
          {
            outline: "none",
          },
        backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        border: "none",
        '& .MuiDataGrid-columnHeaders': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
        '& .MuiDataGrid-overlay': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-filler': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
        '& .MuiDataGrid-footerContainer': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-cell': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiDataGrid-treeDataGroupingCell': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
        },
				'& svg': {
          ...(darkThemeEnabled && { color: DARK_THEME_TEXT_COLOR })
				},
				'& .MuiDataGrid-toolbarContainer svg': {
          color: '#1976D2'
				},
      }}
    />
  );
};

export default ThemedDataGridPro;
