import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { DARK_THEME_DISABLED_BUTTON_BG_COLOR, DARK_THEME_DISABLED_BUTTON_TEXT_COLOR, DARK_THEME_DISABLED_TEXT_COLOR, DARK_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_TEXT_COLOR, LIGHT_THEME_DISABLED_TEXT_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedButton = ({ id, fullWidth, startIcon, variant, onClick, disabled, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <Button
      sx={{
        ...sx,
        ...(disabled && { backgroundColor: darkThemeEnabled ? DARK_THEME_DISABLED_BUTTON_BG_COLOR : '#d8d8d8' }),
        ...(disabled && { color: darkThemeEnabled ? DARK_THEME_DISABLED_BUTTON_TEXT_COLOR : '#959595' }),
        ...(disabled && { 'pointerEvents': 'none' }),
      }}
      variant="contained"
      onClick={onClick}
      fullWidth={fullWidth}
      disableElevation={disabled}
      InputProps={{
        readOnly: disabled,
      }}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );
};

export default ThemedButton;
