import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Tab, useMediaQuery, useTheme } from "@mui/material";
import ProposalDetails from "./ProposalDetails";
import MaterialItems from "./MaterialItems";
import FreightItems from "./FreightItems";
import DemoItems from "./DemoItems";
import BondRater from "./BondRater";
import QuoteBuilder from "./QuoteBuilder";
import ThemedTabs from "./ThemedTabs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3, display: value === index ? "block" : "none" }}>
        {children}
      </Box>
    </div>
  );
}

export default function ProposalTabs() {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <ThemedTabs
          value={value}
          labels={isMdScreen ? ['Proposal Details', 'Material', 'Freight', 'Labor', 'Quote Builder', 'Bond Rater'] : ['Proposal Details', 'Material', 'Freight', 'Labor', 'Bond Rater']}
          onChange={handleChange}
          aria-label="proposal-item-tabs"
          variant="fullWidth"
        >
        </ThemedTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProposalDetails open={value === 0} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialItems value={value} index={1} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FreightItems value={value} index={2} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DemoItems value={value} index={3} />
      </TabPanel>
      { isMdScreen ?
        <TabPanel value={value} index={4}>
          <QuoteBuilder/>
        </TabPanel>
        : null
      }
      <TabPanel value={value} index={isMdScreen ? 5 : 4}>
        <BondRater />
      </TabPanel>
    </Box>
  );
}
