import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import {
  Box,
  Grid,
  MenuItem,
  Button,
  Step,
  StepLabel,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditAccountDialog from "./dialogs/EditAccountDialog";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { decoratedFetch } from "../req_utils";
import {
  createAutogeneratedLog,
  createProjectAssignmentLog,
} from "../log_utils";
import {
  updateAccount,
  markProjectClean,
  updateProject,
  updateProjectKeepClean,
  refreshLogs,
} from "../reducers";
import { PROJECT_STATUSES, GENESIS_LOGO_COLOR } from "../constants";
import ThemedAccordion from "./ThemedAccordion";
import ThemedStepper from "./ThemedStepper";
import ThemedTextField from "./ThemedTextField";
import ThemedSelect from "./ThemedSelect";
import ThemedIconButton from "./ThemedIconButton";
import ThemedButton from "./ThemedButton";
import ThemedPaper from "./ThemedPaper";

const ProjectRibbon = ({ parentOnAccordianExpanded }) => {
  const project = useSelector((state) => state.project);
  const initialStatusId = useRef(null);
  const initialAssigneeId = useRef(null);
  const [currentSalesRepId, setCurrentSalesRepId] = useState(null);
  const employee = useSelector((state) => state.employee);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [employees, setEmployees] = useState([]);
  const [localProjectName, setLocalProjectName] = useState(project?.name);
  const [trigger, setTrigger] = useState(false);
  const { project_id } = useParams();
  const dispatchState = useDispatch();
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);


  const handleLocalProjectNameChange = (event) =>
    setLocalProjectName(event.target.value);

  const handleStatusChange = (index) => {
    const newStatus = { id: index + 1, name: PROJECT_STATUSES[index] };
    if (newStatus.id === project?.status?.id) {
      return;
    }
    updateProjectStatusState(newStatus);
  };

  const handleProjectNameBlur = () => {
    dispatchState(updateProject({ ...project, name: localProjectName }));
  };

  const handleAssigneeChange = (event) => {
    updateProjectAssigneeState(event);
  };

  const updateProjectStatusState = (newStatus) => {
    const updatedProject = {
      ...project,
      status: newStatus,
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectAssigneeState = (event) => {
    const newAssigneeId = event.target.value;
    if (newAssigneeId === project?.assignee?.id) {
      return;
    }
    const updatedProject = {
      ...project,
      assignee: employees.find((employee) => employee.id === newAssigneeId),
    };
    dispatchState(updateProject(updatedProject));
  };

  useEffect(() => {
    setLocalProjectName(project?.name || "");
    setSaveButtonDisabled(!project?.isDirty);

    if (initialStatusId.current === null && project?.status?.id) {
      initialStatusId.current = project.status.id;
    }
    if (initialAssigneeId.current === null && project?.assignee?.id) {
      initialAssigneeId.current = project.assignee.id;
    }
    if (currentSalesRepId === null) {
      setCurrentSalesRepId(project.sales_employee.id);
    }
  }, [project]);

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev); // Toggle the state to force re-render
  };

  useEffect(() => {
    decoratedFetch("/list_employees")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setEmployees(data.filter((employee) => employee.enabled)))
      .catch((error) => {
        console.error(`Error listing employees: ${error}`);
      });
  }, []);

  const handleSave = () => {
    if (!project?.name) {
      setSnackbarMessage("Project name required");
      return;
    }

    if (currentSalesRepId !== project?.sales_employee?.id) {
      setConfirmDialogOpen(true);
      setPendingSave(true);
      return;
    }

    proceedWithSave();
  };


  const proceedWithSave = () => {
    setSaveButtonDisabled(true);

    const payload = {
      ...project,
      status_id: project?.status?.id,
      name: project?.name,
      pm_employee_id: project?.pm_employee?.id,
      start_date: project?.start_date,
      genesis_branch_address_id: project?.genesis_branch_address?.id,
      measured_by_employee_id: project?.measured_by_employee?.id,
      assignee_id: project?.assignee?.id,
      sales_employee_id: project?.sales_employee?.id,
      sales_employee_first_name: project?.sales_employee?.first_name,
      sales_employee_last_name: project?.sales_employee?.last_name,
    };

    decoratedFetch(`/update_project/${project_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error saving project");
        }
        return response.json();
      })
      .then((data) => {
        setSnackbarMessage("Project saved");
        dispatchState(markProjectClean());

        if (data?.custom_id) {
          dispatchState(updateProjectKeepClean({ ...project, custom_id: data.custom_id }));
          setCurrentSalesRepId(project.sales_employee.id);
        } else {
          console.warn("Custom ID missing in response");
        }

        // Check if project status changed and create a log
        if (initialStatusId.current !== project?.status?.id) {
          createAutogeneratedLog(
            project.id,
            employee.id,
            `Project status changed to \"${project.status.name}\" by ${employee.first_name} ${employee.last_name}`,
          )
            .then((response) => {
              if (response.status !== 201) {
                throw new Error("Error occurred");
              }
              initialStatusId.current = project.status.id;
              dispatchState(refreshLogs(true));
            })
            .catch((error) => {
              console.error(`Error creating log: ${error}`);
            });
        }
        // Check if assignee changed and create a log
        else if (initialAssigneeId.current !== project?.assignee?.id && project?.assignee?.id) {
          createProjectAssignmentLog(
            project,
            employee?.id,
            `${employee.first_name} ${employee.last_name} assigned project to ${project.assignee.first_name} ${project.assignee.last_name}`,
          )
            .then((response) => {
              if (response.status !== 201) {
                throw new Error("Error occurred");
              }
              initialAssigneeId.current = project.assignee.id;
              dispatchState(refreshLogs(true));
            })
            .catch((error) => {
              console.error(`Error creating log: ${error}`);
            });
        }
      })
      .catch((error) => {
        console.error("Error saving project:", error);
        setSnackbarMessage("Failed to save project");
        setSaveButtonDisabled(false);
      });
  };

  const handleAccountDialogClose = () => {
    setAccountDialogOpen(false);
  };

  const handleAccountUpdate = (newAccount) => {
    dispatchState(updateAccount(newAccount));
  };

	const copyCustomIdToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(project?.custom_id);
      setSnackbarMessage('Custom ID copied to clipboard');
    } catch (err) {
      console.error("Failed to copy custom ID to clipboard: ", err);
    }
	};

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarMessage.length}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
      <EditAccountDialog
        isOpen={accountDialogOpen}
        handleClose={handleAccountDialogClose}
        account={project?.account}
        triggerParentUpdate={handleAccountUpdate}
      />
      <ThemedAccordion
        sx={{ display: { xs: "none", lg: "block" } }}
        expanded={accordionExpanded}
        disableGutters
      >
        <AccordionSummary
          sx={{
            cursor: "default !important",
          }}
          expandIcon={
            <ThemedIconButton
              onClick={(event) => {
                event.stopPropagation();
                parentOnAccordianExpanded(!accordionExpanded);
                setAccordionExpanded(!accordionExpanded);
              }}
            >
              <ExpandMoreIcon />
            </ThemedIconButton>
          }
          aria-controls="project-ribbon-content"
          id="project-ribbon-header"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Grid container spacing={1} pt={1} sx={{ mr: "15px" }}>
            <Grid item xs={5.8} lg={accordionExpanded ? 3 : 2.25}>
              <ThemedTextField
                label="Project Name"
                fullWidth
                size="small"
                variant="outlined"
                value={localProjectName}
                onChange={handleLocalProjectNameChange}
                onBlur={handleProjectNameBlur}
                InputLabelProps={{ shrink: project?.name?.length > 0 }}
              />
            </Grid>
            <Grid item xs={5.8} lg={2}>
              <div style={{ display: "flex" }}>
                <ThemedTextField
                  sx={{ pr: "6px" }}
                  style={{ pointerEvents: "none" }}
                  label="ID"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={project?.custom_id || ""}
                />
                <ThemedIconButton onClick={() => copyCustomIdToClipboard()}>
                  <ContentCopyIcon />
                </ThemedIconButton>
              </div>
            </Grid>
            <Grid item xs={5.8} lg={accordionExpanded ? 3 : 2.25}>
              <div style={{ display: "flex" }}>
                <ThemedTextField
                  sx={{ pr: "6px" }}
                  style={{ pointerEvents: "none" }}
                  label="Account"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={project?.account?.name || ""}
                  InputLabelProps={{ shrink: true }}
                />
                <ThemedIconButton onClick={() => setAccountDialogOpen(true)}>
                  <EditIcon />
                </ThemedIconButton>
              </div>
            </Grid>
            {accordionExpanded ? null : (
              <Grid
                item
                xs={5.8}
                lg={2.25}
                sx={{ marginTop: { xs: "4px", lg: "0px" } }}
              >
                <ThemedSelect
                  id="status-select"
                  value={project?.status ? project.status?.id - 1 : ""}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  label="Status"
                  size="small"
                  fullWidth
                  renderValue={(selectedId) => PROJECT_STATUSES[selectedId]}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {PROJECT_STATUSES.map((proj_status, i) => (
                    <MenuItem key={i} value={i}>
                      {proj_status}
                    </MenuItem>
                  ))}
                </ThemedSelect>
              </Grid>
            )}
            <Grid
              item
              xs={5.8}
              lg={accordionExpanded ? 3 : 2.25}
              sx={{ marginTop: { xs: "4px", lg: "0px" } }}
            >
              <ThemedSelect
                id="assignee-select"
                value={project?.assignee ? project.assignee?.id : ""}
                onChange={handleAssigneeChange}
                label="Assignee"
                size="small"
                fullWidth
                renderValue={(selectedId) => {
                  const selected = employees.find((e) => e.id === selectedId);
                  return selected
                    ? `${selected.first_name} ${selected.last_name}`
                    : "";
                }}
                MenuProps={{ disableScrollLock: true }}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.first_name} {employee.last_name}
                  </MenuItem>
                ))}
              </ThemedSelect>
            </Grid>
            <Grid item xs={1}>
              <ThemedButton
                fullWidth
                sx={{ ml: 0, mt: { xs: "6px", lg: "1.5px" } }}
                onClick={handleSave}
                startIcon={<SaveIcon />}
                size="medium"
                variant="contained"
                color="primary"
                aria-label="save"
                disabled={saveButtonDisabled}
              >
                Save
              </ThemedButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <ThemedStepper
            activeStep={project?.status?.id - 1}
            alternativeLabel
            sx={{ mb: "-6px" }}
          >
            {PROJECT_STATUSES.map((label, index) => (
              <Step id={index + "_" + (index === project?.status?.id - 1 ? "active" : "inactive")} key={label} onClick={() => handleStatusChange(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </ThemedStepper>
        </AccordionDetails>
      </ThemedAccordion>
      <ThemedPaper
        sx={{
          px: "20px",
          py: "8px",
          display: { xs: "none", md: "block", lg: "none" },
        }}
      >
        <Grid container spacing={1} pt={2}>
          <Grid item xs={12}>
            <ThemedTextField
              label="Project Name"
              fullWidth
              size="small"
              variant="outlined"
              value={localProjectName}
              onChange={handleLocalProjectNameChange}
              onBlur={handleProjectNameBlur}
              InputLabelProps={{ shrink: project?.name?.length > 0 }}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "4px" }}>
            <ThemedTextField
              label="ID"
              fullWidth
              size="small"
              variant="outlined"
              value={project?.custom_id || ""}
              style={{ pointerEvents: "none" }}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "4px" }}>
            <div style={{ display: "flex" }}>
              <ThemedTextField
                sx={{ pr: "6px" }}
                style={{ pointerEvents: "none" }}
                label="Account"
                fullWidth
                size="small"
                variant="outlined"
                value={project?.account?.name || ""}
                InputLabelProps={{ shrink: true }}
              />
              <ThemedIconButton onClick={() => setAccountDialogOpen(true)}>
                <EditIcon />
              </ThemedIconButton>
            </div>
          </Grid>
          <Grid item xs={5.25} lg={5.5} sx={{ marginTop: "4px" }}>
            <ThemedSelect
              id="status-select"
              value={project?.status ? project.status?.id - 1 : ""}
              onChange={(e) => handleStatusChange(e.target.value)}
              label="Status"
              size="small"
              fullWidth
              renderValue={(selectedId) => PROJECT_STATUSES[selectedId]}
              MenuProps={{ disableScrollLock: true }}
            >
              {PROJECT_STATUSES.map((proj_status, i) => (
                <MenuItem key={i} value={i}>
                  {proj_status}
                </MenuItem>
              ))}
            </ThemedSelect>
          </Grid>
          <Grid item xs={5.25} lg={5.5} sx={{ marginTop: "4px" }}>
            <ThemedSelect
              id="assignee-select"
              value={project?.assignee ? project.assignee?.id : ""}
              onChange={handleAssigneeChange}
              label="Assignee"
              size="small"
              fullWidth
              renderValue={(selectedId) => {
                const selected = employees.find((e) => e.id === selectedId);
                return selected
                  ? `${selected.first_name} ${selected.last_name}`
                  : "";
              }}
              MenuProps={{ disableScrollLock: true }}
            >
              {employees.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.first_name} {employee.last_name}
                </MenuItem>
              ))}
            </ThemedSelect>
          </Grid>
          <Grid item xs={1.5} lg={1}>
            <ThemedButton
              fullWidth
              sx={{ mt: "6px" }}
              onClick={handleSave}
              startIcon={<SaveIcon />}
              size="medium"
              variant="contained"
              color="primary"
              aria-label="save"
              disabled={saveButtonDisabled}
            >
              Save
            </ThemedButton>
          </Grid>
        </Grid>
      </ThemedPaper>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The sales representative has changed and the project ID will recalculate. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              setConfirmDialogOpen(false);
              proceedWithSave();
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectRibbon;
