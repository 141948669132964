import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_TEXT_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedDatePicker = ({ size, label, dateAdapter, value, onChange, sx }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        size={size}
        onChange={onChange}
        sx={{
          ...sx,
          "& .MuiInputBase-input": {
            padding: "8.5px 0px 8.5px 14px",
            width: "100%",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              ...(darkThemeEnabled && { borderColor: DARK_THEME_INPUT_OUTLINE_COLOR }),
            },
          },
          '& .MuiInputBase-root': {
            backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR + ' !important' : LIGHT_THEME_PAPER_COLOR,
          },
          "& input": {
            backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
            color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
          },
          "& label": {
            backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
            color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
          },
          "& svg": {
            backgroundColor: darkThemeEnabled ? '#000000' : LIGHT_THEME_PAPER_COLOR,
            color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR,
          },
          "& div.MuiInputBase-root": {
            ...(darkThemeEnabled && { backgroundColor: '#000000' })
          },
          width: "100%",
        }}
      />
    </LocalizationProvider>
  );
};

export default ThemedDatePicker;
