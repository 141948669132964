import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector } from "react-redux";
import { DARK_THEME_DISABLED_BUTTON_BG_COLOR, DARK_THEME_TEXT_COLOR } from "../constants";

const ThemedFormControlLabel = ({ control, label, disabled, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <FormControlLabel
      sx={{
        ...sx,
        '& svg': {
          ...(darkThemeEnabled && { color: disabled ? DARK_THEME_DISABLED_BUTTON_BG_COLOR : DARK_THEME_TEXT_COLOR }),
        },
        '& .MuiFormControlLabel-label': {
          ...(darkThemeEnabled && { color: disabled ? DARK_THEME_DISABLED_BUTTON_BG_COLOR + ' !important' : DARK_THEME_TEXT_COLOR }),
        },
      }}
      control={control}
      label={label}
    >
      {children}
    </FormControlLabel>
  );
};

export default ThemedFormControlLabel;
