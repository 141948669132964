import React, { useState, useEffect } from "react";
import { GridToolbar} from "@mui/x-data-grid-pro";
import { Button, Snackbar } from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from "../constants";
import SavedLaborItemDialog from "./dialogs/SavedLaborItemsDialog";
import ThemedDataGridPro from "./ThemedDataGridPro";

const columns = [
  {
    field: "labor_item_type",
    headerName: "Type",
    flex: 1,
    renderCell: (params) => {
      return params.value.name;
    },
  },
  { field: "demo_item_type", headerName: "Style", flex: 1 },
  {
    field: "size_unit",
    headerName: "Unit",
    flex: 1,
    renderCell: (params) => {
      return params.value.name;
    },
  },
  {
    field: "unit_price",
    headerName: "Price",
    flex: 1,
  },
];

const SavedLaborItems = ({ value, index }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [savedLaborItems, setSavedLaborItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [savedLaborItemsLoading, setSavedLaborItemsLoading] = useState(false);

  const listSavedLaborItems = () => {
    decoratedFetch("/list_saved_labor_items")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setSavedLaborItemsLoading(false);
        setSavedLaborItems(data);
      })
      .catch((error) => {
        setSavedLaborItemsLoading(true);
        console.error(`Error fetching data: ${error}`);
      });
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    setSavedLaborItemsLoading(true);
    listSavedLaborItems();
  }, []);

  const handleRowClick = (params) => {
    setSelectedItem(params.row);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedItem(null);
    setOpen(false);
    listSavedLaborItems(); // Refresh data
  };

  return (
    <div>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ margin: 5 }}
          onClick={() => setOpen(true)}
        >
          Create Labor Item
        </Button>
      </div>
      <div style={{ height: 'auto', width: "100%" }}>
        <ThemedDataGridPro
          rows={savedLaborItems}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          onRowClick={handleRowClick}
          loading={savedLaborItemsLoading}
          localeText={{ noRowsLabel: "No labors" }}
          sx={{
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
              {
                outline: "none",
              },
          }}
        />
      </div>
      <SavedLaborItemDialog
        isOpen={open}
        laborItem={selectedItem}
        handleClose={handleDialogClose}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
    </div>
  );
};

export default SavedLaborItems;
