import React from "react";
import {
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProjectsTable from "../components/ProjectsTable";
import { CreateProjectModal } from "../components/modals/CreateProjectModal";
import { useSelector } from "react-redux";
import ThemedTypography from "../components/ThemedTypography";

const ProjectsPage = () => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  const [open, setOpen] = React.useState(false);
  const employee = useSelector((state) => state.employee);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CreateProjectModal isOpen={open} handleClose={handleClose} />
      <div style={{ margin: "20px", paddingTop: "20px" }}>
        <ThemedTypography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Projects
        </ThemedTypography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%", // Ensure container takes full width of the paper
            paddingBottom: 10,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add New
          </Button>
        </div>
        <div style={{ height: "auto", width: "100%" }}>
          <ProjectsTable />
        </div>
      </div>
    </>
  );
};

export default ProjectsPage;
