import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import { useSelector } from "react-redux";
import { DARK_THEME_INPUT_OUTLINE_COLOR, DARK_THEME_DISABLED_BUTTON_BG_COLOR, DARK_THEME_DISABLED_TEXT_COLOR, DARK_THEME_TEXT_COLOR, DARK_THEME_PAPER_COLOR, LIGHT_THEME_INPUT_OUTLINE_COLOR, LIGHT_THEME_TEXT_COLOR, LIGHT_THEME_PAPER_COLOR } from "../constants";

const ThemedAccordion = ({ expanded, disableGutters, sx, children }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);

  return (
    <Accordion
      expanded={expanded}
      elevation={0}
      disableGutters={disableGutters}
      sx={{
        ...sx,
        backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        borderBottom: "1px solid " + (darkThemeEnabled ? DARK_THEME_INPUT_OUTLINE_COLOR : LIGHT_THEME_INPUT_OUTLINE_COLOR),
        borderLeft: "1px solid " + (darkThemeEnabled ? DARK_THEME_INPUT_OUTLINE_COLOR : LIGHT_THEME_INPUT_OUTLINE_COLOR),
        borderRight: "1px solid " + (darkThemeEnabled ? DARK_THEME_INPUT_OUTLINE_COLOR : LIGHT_THEME_INPUT_OUTLINE_COLOR),
        '& .MuiStepLabel-label': {
          color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR + ' !important' : LIGHT_THEME_TEXT_COLOR,
        },
        '& .MuiAccordionSummary-root': {
          backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR,
        },
      }}
    >
      {children}
    </Accordion>
  );
};

export default ThemedAccordion;
