import React from "react";
import { Grid } from "@mui/material";
import ProposalTabs from "./ProposalTabs";
import QuoteBuilder from "./QuoteBuilder";
import ProposalRibbon from "./ProposalRibbon";
import ThemedPaper from "./ThemedPaper";

const Proposals = () => {
  return (
    <Grid container spacing={2} sx={{ pb: 2.5 }}>
      <Grid
        item
        xs={12}
        sx={{ paddingTop: 0, px: 2, width: "100%", minHeight: "90px" }}
      >
        <ProposalRibbon />
      </Grid>
      <Grid item xs={12} lg={9} sx={{ pt: "6px !important" }}>
        <ThemedPaper
          elevation={3}
          sx={{
            pt: 0,
            px: 2,
            pb: 2,
            width: "100%",
            minHeight: "360px",
            mb: "-20px",
          }}
        >
          <ProposalTabs />
        </ThemedPaper>
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          display: { xs: 'none', lg: 'block' },
          mt: { xs: "20px", lg: 0 },
          px: 2,
          pt: '14px !important',
          width: "100%",
          mb: "-20px",
        }}
      >
        <QuoteBuilder />
      </Grid>
    </Grid>
  );
};

export default Proposals;
