// Check git blame here for previous impl of project details page banner
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Alert,
  Typography,
  Box,
  Grid,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import ProjectDetailsTabs from "../components/ProjectDetailsTabs";
import { decoratedFetch } from "../req_utils";
import ProjectRibbon from "../components/ProjectRibbon";
import { initializeProject, resetProject } from "../reducers";
import {
  LG_HEADER_HEIGHT,
  SM_HEADER_HEIGHT,
  SM_DRAWER_WIDTH,
} from "../constants";
import ClientInformationTabs from "../components/ClientInformationTabs";
import Proposals from "../components/Proposals";
import ThemedPaper from "../components/ThemedPaper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ProjectDetailsPage() {
  const projectRibbonRef = useRef(null);
  const [restOfPageMarginTop, setRestOfPageMarginTop] = useState(0);
  const [projectData, setProjectData] = useState("");
  let { project_id } = useParams();
  const dispatchState = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    //dispatchState(resetProject());
    const updateMarginTop = () => {
      if (projectRibbonRef.current) {
        setRestOfPageMarginTop(projectRibbonRef.current.getBoundingClientRect().bottom);
      }
    };

    // Measure immediately and also on window resize
    updateMarginTop();
    window.addEventListener("resize", updateMarginTop);

    decoratedFetch(`/look_up_project/${project_id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        dispatchState(initializeProject(data));
        setProjectData(data);
      })
      .catch((error) => console.error("Error looking up project: ", error));

    // Cleanup listener to prevent memory leaks
    return () => window.removeEventListener("resize", updateMarginTop);
  }, []);

  const onAccordianExpanded = (expanded) => {
    if (projectRibbonRef.current) {
      setRestOfPageMarginTop(restOfPageMarginTop + (expanded ? 120.03 : -120.03));
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: {
            xs: SM_HEADER_HEIGHT,
            sm: LG_HEADER_HEIGHT,
          },
          zIndex: 1200,
          mr: '16px',
        }}
        ref={projectRibbonRef}
      >
        <Grid container>
          <Grid item xs={12}>
            <ProjectRibbon parentOnAccordianExpanded={onAccordianExpanded}/>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          transition: 'margin-top 0.24s ease',
          marginTop: '16px',
          marginRight: { xs: 0, md: '16px' },
          paddingBottom: '16px',
        }}
      >
        <Grid container spacing={2} sx={{ pl: "0.8px" }}>
          <Grid item xs={12} lg={6}>
            <ThemedPaper
              elevation={3}
              sx={{
                paddingTop: 0,
                px: 2,
                paddingBottom: 2,
                width: "100%",
                height: "630px",
              }}
            >
              <ProjectDetailsTabs projectData={projectData} />
            </ThemedPaper>
          </Grid>
          <Grid item xs={6} sx={{ display: { xs: 'none', lg: 'block' } }}>
            <ThemedPaper
              elevation={3}
              sx={{
                paddingTop: 0,
                px: 2,
                paddingBottom: 2,
                width: "100%",
                height: "630px",
              }}
            >
              <ClientInformationTabs account_id={projectData.account?.id} />
            </ThemedPaper>
          </Grid>
          <Grid item xs={12}>
            <ThemedPaper
              elevation={3}
              sx={{
                paddingTop: 0,
                px: 2,
                py: 2,
                width: "100%",
              }}
            >
              <Proposals />
            </ThemedPaper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
