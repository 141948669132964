import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import { DARK_THEME_PAPER_COLOR, LIGHT_THEME_PAPER_COLOR, DARK_THEME_TEXT_COLOR, LIGHT_THEME_TEXT_COLOR } from "../constants";

const ThemedTabs = ({ value, labels = [], onChange, variant }) => {
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  const [tabs, setTabs] = useState(labels.map((label, i) => <Tab key={i} label={label} sx={{ color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR }} />));

  useEffect(() => {
    setTabs(labels.map((label, i) => <Tab key={i} label={label} sx={{ color: darkThemeEnabled ? DARK_THEME_TEXT_COLOR : LIGHT_THEME_TEXT_COLOR }} />));
  }, [darkThemeEnabled, labels]);

  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant={variant}
      sx={{ backgroundColor: darkThemeEnabled ? DARK_THEME_PAPER_COLOR : LIGHT_THEME_PAPER_COLOR }}
    >
      {tabs}
    </Tabs>
  );
};

export default ThemedTabs;
