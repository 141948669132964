import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Snackbar, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CreateAddressModal } from "./modals/CreateAddressModal";
import { decoratedFetch } from "../req_utils";
import { useParams } from "react-router-dom";
import { refreshProposalLocations } from "../reducers";
import { GENESIS_LOGO_COLOR } from "../constants";
import ThemedPaper from "../components/ThemedPaper";
import ThemedDataGrid from "../components/ThemedDataGrid";
import ThemedIconButton from "../components/ThemedIconButton";

const columns = [
  { field: "addressType", headerName: "Address Type", flex: 2 },
  { field: "line_1", headerName: "Line 1", flex: 3 },
  // { field: 'line_2', headerName: 'Line 2', flex: 1 },
  { field: "city", headerName: "City", flex: 3 },
  { field: "state", headerName: "State", flex: 1 },
  { field: "zip", headerName: "Zip", flex: 2 },
];

function AddressesTab({ value, index, account_id, canDelete, border = false }) {
  const dispatchState = useDispatch();
  const [addresses, setAddresses] = useState([]);
  const [isNew, setNewValue] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { project_id } = useParams();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addressesLoading, setAddressesLoading] = useState(false);
  const handleOpen = () => {
    setNewValue(true);
    setOpen(true);
  };
  const handleRowClick = (params) => {
    setSelectedAddress(addresses.find((address) => address.id === params.id));
    setNewValue(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedAddress(null);
  };
  const [trigger, setTrigger] = useState(false);

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev);
    dispatchState(refreshProposalLocations(true));
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    setAddressesLoading(true);
    if (!account_id && !project_id) {
      return;
    }
    let url = `/list_addresses?account_id=${account_id}`;
    if (project_id) {
      url = `${url}&project_id=${project_id}`;
    }
    decoratedFetch(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setAddresses(
          data.map((item) => {
            return {
              ...item,
              id: item.id,
              addressType: item.project_address_type,
              line_1: item.address.line_1,
              // line_2: item.address.line_2,
              city: item.address.city,
              state: item.address.state,
              zip: item.address.zip,
            };
          }),
        );
        setAddressesLoading(false);
      })
      .catch((error) => {
        setAddressesLoading(false);
        console.error(`Error fetching data: ${error}`);
        setAddresses([]);
      });
  }, [value, account_id, project_id, trigger]);

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <CreateAddressModal
        isOpen={open}
        handleClose={handleClose}
        triggerParentUpdate={triggerParentUpdate}
        project_address={selectedAddress}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        account_id={account_id}
        canDelete={canDelete}
      />
      <div
        style={{
          width: "100%",
          borderBottom: border ? "1px solid #c4c4c4" : "none",
          borderRight: border ? "1px solid #c4c4c4" : "none",
          borderLeft: border ? "1px solid #c4c4c4" : "none",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "8px",
          }}
        >
          <ThemedIconButton
            onClick={handleOpen}
            sx={{ marginLeft: "auto" }}
          >
            <AddIcon />
          </ThemedIconButton>
        </div>
        <ThemedPaper elevation={0} sx={{ height: "508px", width: "100%" }}>
          <ThemedDataGrid
            rows={addresses}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={50}
            onRowClick={handleRowClick}
            disableMultipleSelection
            loading={addressesLoading}
            sx={{
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
                {
                  outline: "none",
                },
              borderLeft: border ? "#c4c4c4" : null,
              borderRight: border ? "#c4c4c4" : null,
              borderBottom: border ? "#c4c4c4" : null,
            }}
            localeText={{ noRowsLabel: "No addresses" }}
          />
        </ThemedPaper>
      </div>
    </>
  );
}

export default AddressesTab;
