import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { updateSelectedProposalDetails } from "../reducers";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from "../constants";
import ThemedTypography from "./ThemedTypography";
import ThemedTextField from "./ThemedTextField";
import ThemedSelect from "./ThemedSelect";
import ThemedFormControlLabel from "./ThemedFormControlLabel";
import ThemedIconButton from "./ThemedIconButton";
import ThemedDataGridPro from "./ThemedDataGridPro";

const ProposalDetails = ({ open }) => {
  const dispatchState = useDispatch();
  const proposal = useSelector((state) => state.selectedProposal);
  const proposalId = useSelector((state) => state.selectedProposal?.id);
  const proposalDetails = useSelector((state) => state.selectedProposalDetails);
  const proposalInclusions = useSelector((state) => state.selectedProposalDetails?.inclusions || []);
  const proposalExclusions = useSelector((state) => state.selectedProposalDetails?.exclusions || []);
  const proposalNotes = useSelector((state) => state.selectedProposalDetails?.notes || []);
  const project = useSelector((state) => state.project);
  const employee = useSelector((state) => state.employee);
  const [proposalTemplates, setProposalTemplates] = useState([]);
  const [selectedInclusions, setSelectedInclusions] = useState([]);
  const [selectedExclusions, setSelectedExclusions] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [materialTotal, setMaterialTotal] = useState(0);
  const [laborTotal, setLaborTotal] = useState(0);
  const [freightTotal, setFreightTotal] = useState(0);
  const [taxableTotal, setTaxableTotal] = useState(0);
  const [addedSalesTax, setAddedSalesTax] = useState(0);
  const [totalAfterSalesTax, setTotalAfterSalesTax] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [recentlyAddedInclusionId, setRecentlyAddedInclusionId] = useState("");
  const [recentlyAddedExclusionId, setRecentlyAddedExclusionId] = useState("");
  const [recentlyAddedNoteId, setRecentlyAddedNoteId] = useState("");
  const inclusionsApiRef = useGridApiRef();
  const exclusionsApiRef = useGridApiRef();
  const notesApiRef = useGridApiRef();
  const enterPressedRef = useRef(false);

  const updateProposal = fieldsToUpdate => {
    dispatchState(updateSelectedProposalDetails(fieldsToUpdate));
    decoratedFetch(`/update_proposal/${proposal.id}`, {
      method: "PUT",
      body: JSON.stringify(fieldsToUpdate),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to save proposal");
        console.error(`Error updating proposal: ${error}`);
      });
  };

  const updateProposalInclusions = ({ inclusion_to_add = null, inclusion_to_update = null, inclusion_to_shift = null, inclusion_ids_to_delete = null }) => {
		let body = {};
		if (inclusion_to_add) {
			body.inclusion_to_add = inclusion_to_add;
			delete body.inclusion_to_add.id;
			delete body.inclusion_to_add.api_id;
		}
		if (inclusion_to_update) {
			body.inclusion_to_update = { ...inclusion_to_update, id: inclusion_to_update.api_id };
			delete body.inclusion_to_update.api_id;
		}
		if (inclusion_to_shift) {
			body.inclusion_to_shift = inclusion_to_shift;
		}
		if (inclusion_ids_to_delete) {
			body.inclusion_ids_to_delete = inclusion_ids_to_delete;
		}
    decoratedFetch(`/update_proposal_inclusions/${proposal.id}`, {
      method: "PUT",
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Error message generated");
        }
				return response.json();
      })
      .then(data => {
				if ('added_inclusion_id' in data) {
					setRecentlyAddedInclusionId(data.added_inclusion_id);
        }
      })
      .catch(error => {
        setSnackbarMessage("Failed to save proposal");
        console.error(`Error updating proposal inclusions: ${error}`);
      });
  };

  const updateProposalExclusions = ({ exclusion_to_add = null, exclusion_to_update = null, exclusion_to_shift = null, exclusion_ids_to_delete = null }) => {
		let body = {};
		if (exclusion_to_add) {
			body.exclusion_to_add = exclusion_to_add;
			delete body.exclusion_to_add.id;
			delete body.exclusion_to_add.api_id;
		}
		if (exclusion_to_update) {
			body.exclusion_to_update = { ...exclusion_to_update, id: exclusion_to_update.api_id };
			delete body.exclusion_to_update.api_id;
		}
		if (exclusion_to_shift) {
			body.exclusion_to_shift = exclusion_to_shift;
		}
		if (exclusion_ids_to_delete) {
			body.exclusion_ids_to_delete = exclusion_ids_to_delete;
		}
    decoratedFetch(`/update_proposal_exclusions/${proposal.id}`, {
      method: "PUT",
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Error message generated");
        }
				return response.json();
      })
      .then(data => {
				if ('added_exclusion_id' in data) {
					setRecentlyAddedExclusionId(data.added_exclusion_id);
        }
      })
      .catch(error => {
        setSnackbarMessage("Failed to save proposal");
        console.error(`Error updating proposal exclusions: ${error}`);
      });
  };

  const updateProposalNotes = ({ note_to_add = null, note_to_update = null, note_to_shift = null, note_ids_to_delete = null }) => {
		let body = {};
		if (note_to_add) {
			body.note_to_add = note_to_add;
			delete body.note_to_add.id;
			delete body.note_to_add.api_id;
		}
		if (note_to_update) {
			body.note_to_update = { ...note_to_update, id: note_to_update.api_id };
			delete body.note_to_update.api_id;
		}
		if (note_to_shift) {
			body.note_to_shift = note_to_shift;
		}
		if (note_ids_to_delete) {
			body.note_ids_to_delete = note_ids_to_delete;
		}
    decoratedFetch(`/update_proposal_notes/${proposal.id}`, {
      method: "PUT",
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Error message generated");
        }
				return response.json();
      })
      .then(data => {
				if ('added_note_id' in data) {
					setRecentlyAddedNoteId(data.added_note_id);
        }
      })
      .catch(error => {
        setSnackbarMessage("Failed to save proposal");
        console.error(`Error updating proposal notes: ${error}`);
      });
  };

  const handleProposalTemplateChange = selectedTemplateId => {
    dispatchState(updateSelectedProposalDetails({ 'proposal_template_id': selectedTemplateId }));
    decoratedFetch(`/overwrite_proposal_with_template/${proposal.id}`, {
      method: "PUT",
      body: JSON.stringify({ 'template_id': selectedTemplateId }),
    })
      .then(response => {
        getProposalDetails();
      })
      .catch((error) => {
        console.error(`Error overwriting proposal with template: ${error}`);
      });
  };

  const descriptionColumns = [
    {
      field: "description",
      headerName: "Description",
      editable: true,
      flex: 1,
    },
  ];

  const handleAddNoteRow = () => {
    const newRow = {
			id: proposalNotes.length,
			api_id: 'temp_id',
      description: "",
    };
    dispatchState(updateSelectedProposalDetails({ 'notes': [...proposalNotes, newRow] }));
		const noteToAdd = { ...newRow };
		noteToAdd.index_num = proposalNotes.length;
    updateProposalNotes({ 'note_to_add': noteToAdd });
  };

  const handleNoteRowDelete = () => {
    dispatchState(updateSelectedProposalDetails({ 'notes': proposalNotes.filter(row => !selectedNotes.includes(row.id)).map((note, i) => ({...note, id: i})) }));
    updateProposalNotes({ 'note_ids_to_delete': selectedNotes.map(id => proposalNotes.find(note => note.id === id)?.api_id) });
    setSelectedNotes([]);
  };

  const handleNoteRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedNotes(newSelectionModel);
    },
    [setSelectedNotes],
  );

  const handleNoteEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      const newRows = proposalNotes.filter(note => note.id !== updatedRow.id).map((note, i) => ({ ...note, id: i }));
			dispatchState(updateSelectedProposalDetails({ 'notes': newRows }));
			updateProposalNotes({ note_ids_to_delete: [updatedRow.api_id === 'temp_id' ? recentlyAddedNoteId : updatedRow.api_id] });
      enterPressedRef.current = false;
      return updatedRow;
    }
		let ops = {};
    const newRows = proposalNotes.map((row) => {
      if (row.id === updatedRow.id) {
        const api_id = updatedRow.api_id === 'temp_id' ? recentlyAddedNoteId : updatedRow.api_id;
        const quantity = parseFloat(updatedRow.quantity) || 0;
        const unitPriceString = updatedRow.unit_price
          ? updatedRow.unit_price.toString()
          : "0";
        const unitPrice =
          parseFloat(unitPriceString.replace(/[^0-9.-]+/g, "")).toFixed(2) || 0;
        const newRow = {
          ...row,
          ...updatedRow,
					api_id: api_id,
          unit_price: `$${unitPrice}`,
          extension: `$${(quantity * unitPrice).toFixed(2)}`,
        };
				ops.note_to_update = { ...newRow };
				return newRow;
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === proposalNotes[proposalNotes.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = {
        id: proposalNotes.length,
        api_id: 'temp_id',
        quantity: "",
        unit: "",
        description: "",
        unit_price: "",
        extension: "",
        category: "",
      };
      newRows.push(newRow);
			ops.note_to_add = { ...newRow };
			ops.note_to_add.index_num = proposalNotes.length;
    }
    enterPressedRef.current = false;
		dispatchState(updateSelectedProposalDetails({ 'notes': newRows }));
		updateProposalNotes(ops);
    return updatedRow;
  };

  const handleExclusionEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      const newRows = proposalExclusions.filter(excl => excl.id !== updatedRow.id).map((excl, i) => ({ ...excl, id: i }));
			dispatchState(updateSelectedProposalDetails({ 'exclusions': newRows }));
			updateProposalExclusions({ exclusion_ids_to_delete: [updatedRow.api_id === 'temp_id' ? recentlyAddedExclusionId : updatedRow.api_id] });
      enterPressedRef.current = false;
      return updatedRow;
    }
		let ops = {};
    const newRows = proposalExclusions.map((row) => {
      if (row.id === updatedRow.id) {
        const api_id = updatedRow.api_id === 'temp_id' ? recentlyAddedExclusionId : updatedRow.api_id;
        const quantity = parseFloat(updatedRow.quantity) || 0;
        const unitPriceString = updatedRow.unit_price
          ? updatedRow.unit_price.toString()
          : "0";
        const unitPrice =
          parseFloat(unitPriceString.replace(/[^0-9.-]+/g, "")).toFixed(2) || 0;
        const newRow = {
          ...row,
          ...updatedRow,
					api_id: api_id,
          unit_price: `$${unitPrice}`,
          extension: `$${(quantity * unitPrice).toFixed(2)}`,
        };
				ops.exclusion_to_update = { ...newRow };
				return newRow;
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === proposalExclusions[proposalExclusions.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = {
        id: proposalExclusions.length,
        api_id: 'temp_id',
        quantity: "",
        unit: "",
        description: "",
        unit_price: "",
        extension: "",
        category: "",
      };
      newRows.push(newRow);
			ops.exclusion_to_add = { ...newRow };
			ops.exclusion_to_add.index_num = proposalExclusions.length;
    }
    enterPressedRef.current = false;
		dispatchState(updateSelectedProposalDetails({ 'exclusions': newRows }));
		updateProposalExclusions(ops);
    return updatedRow;
  };

  const handleAddExclusionRow = () => {
    const newRow = {
			id: proposalExclusions.length,
			api_id: 'temp_id',
      description: "",
    };
    dispatchState(updateSelectedProposalDetails({ 'exclusions': [...proposalExclusions, newRow] }));
		const exclusionToAdd = { ...newRow };
		exclusionToAdd.index_num = proposalExclusions.length;
    updateProposalExclusions({ 'exclusion_to_add': exclusionToAdd });
  };

  const handleExclusionRowDelete = () => {
    dispatchState(updateSelectedProposalDetails({ 'exclusions': proposalExclusions.filter(row => !selectedExclusions.includes(row.id)).map((excl, i) => ({...excl, id: i})) }));
    updateProposalExclusions({ 'exclusion_ids_to_delete': selectedExclusions.map(id => proposalExclusions.find(excl => excl.id === id)?.api_id) });
    setSelectedExclusions([]);
  };

  const handleExclusionRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedExclusions(newSelectionModel);
    },
    [setSelectedExclusions],
  );

  const handleInclusionEditCommit = updatedRow => {
    if (!updatedRow.description) {
      const newRows = proposalInclusions.filter(incl => incl.id !== updatedRow.id).map((incl, i) => ({ ...incl, id: i }));
			dispatchState(updateSelectedProposalDetails({ 'inclusions': newRows }));
			updateProposalInclusions({ inclusion_ids_to_delete: [updatedRow.api_id === 'temp_id' ? recentlyAddedInclusionId : updatedRow.api_id] });
      enterPressedRef.current = false;
      calculateTotals(newRows);
      return updatedRow;
    }
		let ops = {};
    const newRows = proposalInclusions.map((row) => {
      if (row.id === updatedRow.id) {
        const api_id = updatedRow.api_id === 'temp_id' ? recentlyAddedInclusionId : updatedRow.api_id;
        const quantity = parseFloat(updatedRow.quantity) || 0;
        const unitPriceString = updatedRow.unit_price
          ? updatedRow.unit_price.toString()
          : "0";
        const unitPrice =
          parseFloat(unitPriceString.replace(/[^0-9.-]+/g, "")).toFixed(2) || 0;
        const newRow = {
          ...row,
          ...updatedRow,
					api_id: api_id,
          unit_price: `$${unitPrice}`,
          extension: `$${(quantity * unitPrice).toFixed(2)}`,
        };
				ops.inclusion_to_update = { ...newRow };
				return newRow;
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === proposalInclusions[proposalInclusions.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = {
        id: proposalInclusions.length,
        api_id: 'temp_id',
        quantity: "",
        unit: "",
        description: "",
        unit_price: "",
        extension: "",
        category: "",
      };
      newRows.push(newRow);
			ops.inclusion_to_add = { ...newRow };
			ops.inclusion_to_add.index_num = proposalInclusions.length;
    }
    enterPressedRef.current = false;
		dispatchState(updateSelectedProposalDetails({ 'inclusions': newRows }));
		updateProposalInclusions(ops);
    calculateTotals(newRows);
    return updatedRow;
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    if (proposalInclusions?.length) {
      calculateTotals(proposalInclusions);
    }
  }, [proposalInclusions, open]);

  const handleAddInclusionRow = () => {
    const newRow = {
			id: proposalInclusions.length,
			api_id: 'temp_id',
      quantity: "",
      unit: "",
      description: "",
      unit_price: "",
      extension: "",
      category: "",
    };
    dispatchState(updateSelectedProposalDetails({ 'inclusions': [...proposalInclusions, newRow] }));
		const inclusionToAdd = { ...newRow };
		inclusionToAdd.index_num = proposalInclusions.length;
    updateProposalInclusions({ 'inclusion_to_add': inclusionToAdd });
  };

  const handleInclusionRowDelete = () => {
    const newRows = proposalInclusions.filter(row => !selectedInclusions.includes(row.id)).map((incl, i) => ({...incl, id: i}));
    dispatchState(updateSelectedProposalDetails({ 'inclusions': newRows }));
    updateProposalInclusions({ 'inclusion_ids_to_delete': selectedInclusions.map(id => proposalInclusions.find(incl => incl.id === id)?.api_id) });
    setSelectedInclusions([]);
    calculateTotals(newRows);
  };

  const itemizedColumns = [
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      editable: true,
      flex: 1,
    },
    { field: "unit",
      headerName: "Unit",
      editable: true,
      flex: 0.5,
    },
    {
      field: "category",
      headerName: "Category",
      editable: true,
      width: 175,
      type: "singleSelect",
      valueOptions: ["Material", "Freight", "Labor"],
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      width: 380,
      flex: 3,
    },
    {
      field: "unit_price",
      headerName: "Unit Price",
      type: "number",
      editable: true,
      flex: 1,
    },
    {
      field: "extension",
      headerName: "Extension",
      type: "number",
      editable: false,
      flex: 1,
    },
  ];

  const handleInclusionRowSelectionModelChange = useCallback(newSelectionModel => {
		setSelectedInclusions(newSelectionModel);
	}, [setSelectedInclusions]);

  const calculateTotals = (newRows) => {
    let materialTotal = 0;
    let laborTotal = 0;
    let freightTotal = 0;

    newRows.forEach((row) => {
      if (!row.extension) {
        return;
      }
      const extension =
        parseFloat(row.extension.replace(/[^0-9.-]+/g, "")) || 0;
      switch (row.category) {
        case "Material":
          materialTotal += extension;
          break;
        case "Labor":
          laborTotal += extension;
          break;
        case "Freight":
          freightTotal += extension;
          break;
        default:
          break;
      }
    });

    if (proposalDetails.include_sales_tax) {
      let salesTaxRate = 0;
      salesTaxRate = parseFloat(proposal.material_sales_tax_rate) / 100;
      const taxableTotal = parseFloat(materialTotal) + parseFloat(freightTotal);
      const addedSalesTax = taxableTotal * salesTaxRate;
      const totalAfterSalesTax =
        parseFloat(materialTotal) +
        parseFloat(freightTotal) +
        parseFloat(laborTotal) +
        addedSalesTax;
      setTaxableTotal(taxableTotal);
      setAddedSalesTax(addedSalesTax);
      setTotalAfterSalesTax(totalAfterSalesTax);
    }

    setMaterialTotal(materialTotal.toFixed(2));
    setLaborTotal(laborTotal.toFixed(2));
    setFreightTotal(freightTotal.toFixed(2));
  };

  const listProposalTemplates = () => {
    decoratedFetch("/list_proposal_templates")
      .then((response) => response.json())
      .then((data) => {
        setProposalTemplates(data);
      })
      .catch((error) => {
        console.error(`Error listing proposal templates: ${error}`);
      });
  };

  const getProposalDetails = () => {
    decoratedFetch(`/look_up_proposal_details?proposal_id=${proposal?.id}`)
      .then((response) => response.json())
      .then((data) => {
        const inclusionsWithNewIds =
          data?.inclusions?.map((incl, index) => ({
            ...incl,
						api_id: incl.id,
            id: index,
          })) || [];
        const exclusionsWithNewIds =
          data?.exclusions?.map((excl, index) => ({
            ...excl,
						api_id: excl.id,
            id: index,
          })) || [];
        const notesWithNewIds =
          data?.notes?.map((note, index) => ({
            ...note,
						api_id: note.id,
            id: index,
          })) || [];
        dispatchState(updateSelectedProposalDetails({ ...data, 'inclusions': inclusionsWithNewIds, 'exclusions': exclusionsWithNewIds, 'notes': notesWithNewIds }));
      })
      .catch((error) => {
        console.error(`Error looking up proposal details: ${error}`);
      });
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    if (proposal && Object.keys(proposal).length !== 0) {
      getProposalDetails();
    }
    listProposalTemplates();
  }, [open, proposalId]);

  const importProposalItems = item_type => {
    decoratedFetch('/import_proposal_items_to_inclusions', {
      method: "POST",
      body: JSON.stringify({
        'proposal_id': proposal.id,
        'item_type': item_type,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          dispatchState(updateSelectedProposalDetails({ 'inclusions': data.map((incl, i) => ({ ...incl, api_id: incl.id, id: i })) }));
          return;
        }
				throw new Error("Error message generated");
      })
      .catch((error) => {
        console.error(`Error importing ${item_type} to inclusions: ${error}`);
      });
  };

  const handleCellKeyDown = (params, event) => {
    if (event.key === "Enter") {
      enterPressedRef.current = true;
      return;
    }
    enterPressedRef.current = false;
  };

  return (
    <Grid
      container
      spacing={1}
    >
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
      <Grid item xs={12} sm={6} sx={{ mb: "10px" }}>
        <ThemedSelect
          id="template-select"
          value={proposalDetails?.proposal_template_id ? proposalDetails?.proposal_template_id : ""}
          onChange={e => handleProposalTemplateChange(e.target.value)}
          label="Template"
          fullWidth
          formControlSize="small"
          formControlSx={{
            minWidth: 120,
            width: "100%",
          }}
        >
          {proposalTemplates.length ? proposalTemplates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          )) : null}
        </ThemedSelect>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ pr: '10px', mb: "10px" }}>
        <ThemedTextField
          label="Contract ID"
          variant="outlined"
          fullWidth
          size="small"
          value={proposalDetails?.cmas_id ? proposalDetails.cmas_id : ''}
          onChange={(e) => dispatchState(updateSelectedProposalDetails({ 'cmas_id': e.target.value }))}
          InputLabelProps={{ shrink: proposalDetails?.cmas_id }}
          onBlur={(e) => updateProposal({ 'cmas_id': e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "12px" }}>
        <ThemedTextField
          label="Included Area"
          variant="outlined"
          fullWidth
          size="small"
          value={proposalDetails?.included_area ? proposalDetails.included_area : ''}
          onChange={(e) => dispatchState(updateSelectedProposalDetails({ 'included_area': e.target.value }))}
          InputLabelProps={{ shrink: proposalDetails?.included_area }}
          onBlur={(e) => updateProposal({ 'included_area': e.target.value })}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ pr: '10px', display: "flex", justifyContent: "space-around" }}
      >
        <Button
          size="small"
          onClick={() => importProposalItems('material')}
          fullWidth
          sx={{ pt: "8px" }}
        >
          Import Materials
        </Button>
        <Button
          size="small"
          onClick={() => importProposalItems('freight')}
          fullWidth
          sx={{ pt: "8px" }}
        >
          Import Freight
        </Button>
        <Button
          size="small"
          onClick={() => importProposalItems('labor')}
          fullWidth
          sx={{ pt: "8px" }}
        >
          Import Labor
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ pr: '10px', display: "flex", justifyContent: "space-around" }}
      >
        <ThemedFormControlLabel
          control={
            <Checkbox
              checked={proposalDetails?.is_itemized}
              onChange={e => updateProposal({ 'is_itemized': e.target.checked, 'include_price_category_breakdown': e.target.checked ? proposalDetails.include_price_category_breakdown : false, 'include_sales_tax': e.target.checked ? proposalDetails.include_sales_tax : false })}
            />
          }
          label="Itemize"
        />
        <ThemedFormControlLabel
          control={
            <Checkbox
              checked={proposalDetails?.include_price_category_breakdown}
              onChange={e => updateProposal({ 'include_price_category_breakdown' : e.target.checked })}
              disabled={!proposalDetails?.is_itemized}
            />
          }
          disabled={!proposalDetails?.is_itemized}
          label="MFL Breakdown"
        />
        <ThemedFormControlLabel
          control={
            <Checkbox
              checked={proposalDetails?.include_sales_tax}
              onChange={e => updateProposal({ 'include_sales_tax': e.target.checked })}
              disabled={!proposalDetails?.is_itemized}
            />
          }
          disabled={!proposalDetails?.is_itemized}
          label="Sales Tax"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          pr: "10px",
          mt: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ThemedTypography sx={{ fontSize: "1.2rem" }}>Inclusions</ThemedTypography>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedInclusions.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <ThemedIconButton
                onClick={handleInclusionRowDelete}
              >
                <RemoveIcon />
              </ThemedIconButton>
            )}
          </Grid>
          <Grid item>
            <ThemedIconButton
              onClick={handleAddInclusionRow}
            >
              <AddIcon />
            </ThemedIconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "11px", width: "100%" }}>
        {proposalDetails?.inclusions?.length ? (
          <ThemedDataGridPro
            apiRef={inclusionsApiRef}
            onRowCountChange={() => {
							if (proposalInclusions?.length && proposalInclusions[proposalInclusions.length - 1].api_id === 'temp_id') {
								inclusionsApiRef.current.setCellFocus(
									proposalInclusions.length - 1,
									'description',
								);
								inclusionsApiRef.current.startCellEditMode({
									id: proposalInclusions.length - 1,
									field: "description",
								});
							}
						}}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                display: proposalDetails?.is_itemized ? "block" : "none",
              },
							"& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within": {
								outline: "none",
							},
            }}
            autoHeight
            hideFooter
            rows={proposalInclusions}
            columns={proposalDetails?.is_itemized ? itemizedColumns : descriptionColumns}
            processRowUpdate={handleInclusionEditCommit}
            onProcessRowUpdateError={(error) => console.error(error)}
            experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            onRowSelectionModelChange={(newSelectionModel) =>
              handleInclusionRowSelectionModelChange(newSelectionModel)
            }
            rowSelectionModel={selectedInclusions}
            getRowId={(row) => row.id}
            onCellClick={(params, e) => e.stopPropagation()}
            localeText={{ noRowsLabel: "No Inclusions" }}
            onCellKeyDown={handleCellKeyDown}
            rowReordering
            onRowOrderChange={(e) => {
              let mutableInclusions = [...proposalDetails?.inclusions];
              const sourceRow = mutableInclusions[e.oldIndex];
              mutableInclusions.splice(e.oldIndex, 1);
              mutableInclusions.splice(e.targetIndex, 0, sourceRow);
              dispatchState(updateSelectedProposalDetails({ 'inclusions': mutableInclusions.map((incl, i) => ({...incl, id: i})) }));
              updateProposalInclusions({ inclusion_to_shift: { id: sourceRow.api_id, target_idx: e.targetIndex } });
            }}
          />
        ) : null}
      </Grid>
      {proposalDetails?.include_price_category_breakdown && (
        <>
          <Grid
            item
            xs={12}
            sm={3.25}
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <ThemedTypography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Material Total: <strong>${materialTotal}</strong>
            </ThemedTypography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <ThemedTypography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Freight Total: <strong>${freightTotal}</strong>
            </ThemedTypography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3.5}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <ThemedTypography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Labor Total: <strong>${laborTotal}</strong>
            </ThemedTypography>
          </Grid>
        </>
      )}
      {proposalDetails?.include_sales_tax && (
        <>
          <Grid
            item
            xs={12}
            sm={3.25}
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <ThemedTypography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Taxable Total: <strong>${taxableTotal.toFixed(2)}</strong>
            </ThemedTypography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <ThemedTypography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Added Sales Tax: <strong>${addedSalesTax.toFixed(2)}</strong>
            </ThemedTypography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3.5}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <ThemedTypography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Total: <strong>${totalAfterSalesTax.toFixed(2)}</strong>
            </ThemedTypography>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sx={{
          pr: '10px',
          mt: proposalDetails?.include_price_category_breakdown || proposalDetails?.include_sales_tax ? "10px" : "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ThemedTypography sx={{ fontSize: "1.2rem" }}>Exclusions</ThemedTypography>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedExclusions.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <ThemedIconButton
                onClick={handleExclusionRowDelete}
              >
                <RemoveIcon />
              </ThemedIconButton>
            )}
          </Grid>
          <Grid item>
            <ThemedIconButton
              onClick={handleAddExclusionRow}
            >
              <AddIcon />
            </ThemedIconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "11px", width: "100%" }}>
        {proposalDetails?.exclusions?.length ? (
          <ThemedDataGridPro
            apiRef={exclusionsApiRef}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                display: "none",
              },
							"& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within": {
								outline: "none",
							},
            }}
            onRowCountChange={() => {
							if (proposalExclusions?.length && proposalExclusions[proposalExclusions.length - 1].api_id === 'temp_id') {
								exclusionsApiRef.current.setCellFocus(
									proposalExclusions.length - 1,
									'description',
								);
								exclusionsApiRef.current.startCellEditMode({
									id: proposalExclusions.length - 1,
									field: "description",
								});
							}
						}}
            autoHeight
            hideFooter
            rows={proposalDetails?.exclusions}
            columns={descriptionColumns}
            processRowUpdate={handleExclusionEditCommit}
            onProcessRowUpdateError={(error) => console.error(error)}
            experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            onRowSelectionModelChange={
              handleExclusionRowSelectionModelChange
            }
            rowSelectionModel={selectedExclusions}
            getRowId={(row) => row.id}
            onCellClick={(params, e) => e.stopPropagation()}
            onCellKeyDown={handleCellKeyDown}
            localeText={{ noRowsLabel: "No Exclusions" }}
            rowReordering
            onRowOrderChange={(e) => {
              let mutableExclusions = [...proposalDetails?.exclusions];
              const sourceRow = mutableExclusions[e.oldIndex];
              mutableExclusions.splice(e.oldIndex, 1);
              mutableExclusions.splice(e.targetIndex, 0, sourceRow);
              dispatchState(updateSelectedProposalDetails({ 'exclusions': mutableExclusions.map((excl, i) => ({...excl, id: i})) }));
              updateProposalExclusions({ exclusion_to_shift: { id: sourceRow.api_id, target_idx: e.targetIndex } });
            }}
          />
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          pr: '10px',
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ThemedTypography sx={{ fontSize: "1.2rem" }}>Notes</ThemedTypography>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedNotes.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <ThemedIconButton
                onClick={handleNoteRowDelete}
              >
                <RemoveIcon />
              </ThemedIconButton>
            )}
          </Grid>
          <Grid item>
            <ThemedIconButton
              onClick={handleAddNoteRow}
            >
              <AddIcon />
            </ThemedIconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "11px", width: "100%" }}>
        {proposalDetails?.notes?.length ? (
          <ThemedDataGridPro
            apiRef={notesApiRef}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                display: "none",
              },
							"& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within": {
								outline: "none",
							},
            }}
            onRowCountChange={() => {
							if (proposalNotes?.length && proposalNotes[proposalNotes.length - 1].api_id === 'temp_id') {
								notesApiRef.current.setCellFocus(
									proposalNotes.length - 1,
									'description',
								);
								notesApiRef.current.startCellEditMode({
									id: proposalNotes.length - 1,
									field: "description",
								});
							}
						}}
            autoHeight
            hideFooter
            rows={proposalDetails?.notes}
            columns={descriptionColumns}
            processRowUpdate={handleNoteEditCommit}
            onProcessRowUpdateError={(error) => console.error(error)}
            experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            onRowSelectionModelChange={handleNoteRowSelectionModelChange}
            rowSelectionModel={selectedNotes}
            getRowId={(row) => row.id}
            onCellClick={(params, e) => e.stopPropagation()}
            onCellKeyDown={handleCellKeyDown}
            rowReordering
            localeText={{ noRowsLabel: "No Notes" }}
            onRowOrderChange={(e) => {
              let mutableNotes = [...proposalDetails?.notes];
              const sourceRow = mutableNotes[e.oldIndex];
              mutableNotes.splice(e.oldIndex, 1);
              mutableNotes.splice(e.targetIndex, 0, sourceRow);
              dispatchState(updateSelectedProposalDetails({ 'notes': mutableNotes.map((note, i) => ({...note, id: i})) }));
              updateProposalNotes({ note_to_shift: { id: sourceRow.api_id, target_idx: e.targetIndex } });
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ProposalDetails;
