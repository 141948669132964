import React, { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CreateAccountModal } from "../components/modals/CreateAccountModal";
import { decoratedFetch } from "../req_utils";
import EditAccountDialog from "../components/dialogs/EditAccountDialog";
import ContactsTab from "../components/ContactsTab";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import ThemedTypography from "../components/ThemedTypography";
import ThemedDataGridPro from "../components/ThemedDataGridPro";

const AccountsPage = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [filterModel, setFilterModel] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  const columns = [
    {
      field: "account_name",
      headerName: "Account Name",
      width: 350,
      editable: false,
      flex: 3,
    },
    {
      field: "account_type",
      headerName: "Account Type",
      width: 200,
      editable: false,
      flex: 2,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 600,
      editable: false,
      flex: 7,
    },
  ];

  const rows = accounts?.map((account, index) => ({
    id: account.id,
    account_name: account.name,
    account_type: account.account_type.name,
    notes: account?.notes,
  }));

  const handleRowClick = (params) => {
    setSelectedAccount(accounts.find((account) => account.id === params.id));
    setEdit(true);
  };

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev); // Toggle the state to force re-render
  };

  useEffect(() => {
    setFilterModel(filterModel);
  }, []);

  useEffect(() => {
    setAccountsLoading(true);
    decoratedFetch("/list_accounts")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setAccountsLoading(false);
        setAccounts(data);
      })
      .catch((error) => {
        setAccountsLoading(false);
        console.error("Error fetching data: ", error);
      });
  }, [trigger]);

  return (
    <>
      <EditAccountDialog
        isOpen={edit}
        handleClose={handleClose}
        account={selectedAccount}
        triggerParentUpdate={triggerParentUpdate}
      />
      <CreateAccountModal
        isOpen={open}
        handleClose={handleClose}
        triggerParentUpdate={triggerParentUpdate}
        onAccountCreated={(newAccount) => {
          setSelectedAccount(newAccount);
          setEdit(true);
        }}
      />
      <div style={{ margin: "20px", paddingTop: "20px" }}>
        <ThemedTypography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Accounts
        </ThemedTypography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%", // Ensure container takes full width of the paper
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add New
          </Button>
        </div>
        <div style={{ height: 'auto', width: "100%", paddingTop: 10 }}>
          <ThemedDataGridPro
            slots={{ toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            pageSize={50}
            disableSelectionOnClick
            disableMultipleSelection
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            onRowClick={handleRowClick}
            localeText={{ noRowsLabel: "No accounts" }}
            loading={accountsLoading}
          />
        </div>
      </div>
    </>
  );
};

export default AccountsPage;
